import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
export const frontmatter = {
  title: "In The Day of Digital, Retail's Very Human Problem",
  date: "2018-09-20",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/20180920-0.jpg",
  path: "2018-09-20-Retails-Human-Problem",
  teaserText: "Consumers have simply walked away from slow-moving cashier aisles and irrelevantly timed merchandise into Amazon and its brethren."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="In The Day of Digital, Retail's Very Human Problem" description="Consumers have simply walked away from slow-moving cashier aisles and irrelevantly timed merchandise into Amazon and its brethren." author='Roberto Ramos' keywords={['Robots']} />
      <ImageLandscape imageSrc="20180920-1.jpg" />
      <MDXTag name="p" components={components}>{`We have all been there. The minute you decide to abandon the items you were thinking of buying because of a long line. Or the turn-off moment when the customer service person simply does not live up to that promise. Multiply moments like this times a million-plus when a brand ignores you and you have a sizeable reason for retail's dismal state.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Consumers have simply walked away from slow-moving cashier aisles and irrelevantly timed merchandise into Amazon and its brethren. The balance of innovation, creativity and performance has swung almost unilaterally towards the digital domain. However, the current lack of personal experience for digital needs a recalibration. Digital growth will need a better physical experience to thrive. A more focused human lens will bring both digital and physical together. Unfortunately, we are not even close yet.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Despite algorithms winning the efficiency war, there will be a stronger model in the near- and mid-future for a more thoughtful and inspiring form of retail to prevail, both online and off. And this next incarnation will be predicated on the human experience, with talent (one of retail's most ignored variables) perched at the top. For retail to thrive again, it must get why and how people would want to engage with it. The potential is great as retail and fashion are still some of the most powerful and fun ways to embrace self-discovery and gratification. We must rekindle this feeling. Here are six ways to do it.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1. Retail's Human Proposition, Beginning at Home`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`For an industry that should be all about customer service magic, retail needs to do a better job at getting its practitioners and employees to fall in love with it. The industry has a morale problem that requires an immediate fix. Retail must become the progressive, innovative player that it professes to be. And it should begin by bringing back the right type of talent. It should cast a wider, more creative net with a focus on people that get culture, lifestyle, fashion and all the pieces that make the persuasive sale of an individual product stronger. Old-school merchandising will no longer cut it. Our industry needs to think more like a culture factory; fluid and open in its ideas. At a time when the job market is highly competitive, let's think of new areas of responsibilities, titles and performance metrics, including experiential, artful selling, empathy, insightfulness, etc.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2. Elevate Customer Service`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`As most of what was until recently bad customer service gets outsourced to bots and supply chain systems, retailers both physical and digital, need to dial up the human touch. The industry needs to look to Hollywood and Broadway for inspiration, with service as the base foundation. Think of what Apple does with its Genius Bar and its “Today at Apple” community experience, or Starbucks with its baristas. Or how digital-first Stitch Fix has built an amplified algorithm that is part data, part human.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Empower the new wave of emotive professionals with better systems, backdrops and conversations to give more space to human interactions, with technology as the informed facilitator. Rethink the floor to be more of a lounge, plaza and community; places where consumers feel naturally comfortable to connect and explore.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3. Make Retail Entrepreneurial`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Retail is one of the industries most challenged by disruptive entrepreneurs. This is because its bad service is riddled with opportunities for others to get it right, and also because many startups have a passion for retail. Leverage this entrepreneurial interest to create more new ventures and out-of-the box opportunities within your company. Start an innovation fund; give your employees the opportunity to pitch you new ideas, and then encourage and incentivize risk-taking. Cast rebels, outliers and nonconformists within teams. Simply put, the future of retail will not be business as usual, and you need independent thinkers to keep you fresh and creative.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4. Incentivize Consumer-Centricity`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`In order for your company to thrive at selling to people, it should work on understanding and connecting with people better than anyone else. This concept might not seem very novel, but the current state of our industry serves as a stark reminder of how badly this tenet is applied. Invest in more ways to have your employees connect with consumers, provide them with better insights and information regarding your customers and rethink your incentive systems to give more weight to finding out why people are not shopping with you, etc. Push product development and marketing teams to frame new initiatives from the lens and journey of your consumer: how will it improve and add magic to their lives?`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`5. Lead with Purpose`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Let's face it, fashion and retail have a real image problem. Modern consumers think many negative things when they think of us: disgruntled employees, low morale, craziness on the floors during Black Friday. But most important, social justice rears its ugly head when they think of the human factor in outsourcing practices including sweatshops and the manufacturing impact on the environment. Invest in the power of becoming a radically transparent and integral company. Find ways of making this profitable through innovation. Doing good is the right thing to do if you're building a brand for life. The modern consumer will respond positively.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`6. Above All Else, Make It Personal`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`In an industry that is burdened with the term omnichannel, I prefer omnipersonal — finding all the ways in which you can be as insanely relevant as possible. AI technology is largely helping in this domain with better information on both the store level as well as individual shopping behavior. But there is still an opportunity to make products, messaging and engagement more personal. Think of the potential of customized interfaces, 3-D printing, blockchain-type transparency, as well as personally curated product journeys. Personalization should also expand to the physical environment by dialing up in-store events, personalized product and personal shoppers.`}</MDXTag>
      <MDXTag name="p" components={components}>{`As our society becomes more digitally dependent, we will seek more analog and human outlets for connectivity. We will also need an escape and a creative journey to explore the self. Good ole fashion and retail should do this, especially if we get to the point where we outsource all the headaches to technology smarter than we are. The remaining space for magic should be all of us. Falling in love, after all, is not yet an algorithm. Let's make retail magical again.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    